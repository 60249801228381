@import '../_vars';
.card{
  border: 5px solid $color_text;
  &.temporary{
    // background: $color_temporary;
    border: 5px solid $color_temporary;
  }
  &.model{
    // background-color: $color_model !important;
    border: 5px solid $color_model;
  }
  .MuiCardActions-root{
    background-color: $color_text !important;
    // padding: 16px !important;
    > div{
      flex: 1;
    }
    .notes{
      flex: 1;
    }
    .themes{
      text-align: right;
      font-style: italic;
      opacity: 0.6;
    }
  }
  .mainItem{
    margin: 0 1rem;
    font-style: italic;
    font-size: 0.9rem;
  }
}