@import '../_vars';
.chip-container{
  min-height: 32px;
  outline: 0;
  padding: 0;
  font-size: 0.8125rem;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background: rgba(255,255,255,0.1);
  margin-bottom: 5px;
  width: 100%;
  // display: block;
  display: flex; 
  justify-content: flex-start; //space-between; //
  position: relative;

  .notes{
    display: inline-block;
    vertical-align: middle;
    padding-left: 2rem;
    font-size: small;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    padding-right: 12px;
  }
  .quantity{
    margin: 0 0.3rem;
    opacity: 0.6;
    border-color: $color_text;
    color: $color_text;
  }

  .themes{
    position: absolute;
    bottom: 0.2rem;
    right: 0.7rem;
    display: inline-block;
    font-style: italic;
    opacity: 0.6;
  }

  .toggleSubItemsButton{
    color: $color_text; 
    position: absolute;
    top: 0;
    right: 0;
  }


  &.model{

    border-radius: 4px;
    .MuiChip-root{
      border-radius: 4px;
    }
  }
  &.temporary{
    .MuiChip-root:not(.MuiChip-outlined){
      background: $color_temporary;
    }
    .MuiChip-root.MuiChip-outlined{
      border-color: $color_temporary;
      color: $color_temporary;
    }
  }
}