@import '../_vars';

.fab-right{
  position: fixed !important;
  bottom: 2rem;
  right: 1rem;
  z-index: 2;
  &.fab-right-2{
    bottom: calc( 3rem + 56px );
    right: 1.5rem;
  }
  &.fab-right-3{
    bottom: calc( 3rem + 112px );
    right: 1.5rem;
  }
  &.fab-right-4{
    bottom: calc( 3rem + 168px );
    right: 1.5rem;
  }
  &.fab-right-5{
    bottom: calc( 3rem + 224px );
    right: 1.5rem;
  }
  &.fab-right-6{
    bottom: calc( 3rem + 280px );
    right: 1.5rem;
  }
  .label{
    position: absolute;
    left: -1rem;
    top: 50%;
    white-space: nowrap;
    transform: translate(-100%,-50%);
    text-align: right;
    line-height: 1;
    small{
      font-size: 0.8em;
      font-style: italic;
    }
  }
}

.home-logo{
  overflow: hidden;
  text-align: center;
  .logo{
    width: 5rem;
    max-width: 50%;
    max-height: 56px;
  }
  @media (prefers-reduced-motion: no-preference) {
    .logo{ animation: App-logo-spin-small infinite 10s linear; }
  }
}
@keyframes App-logo-spin-small {
  0% { transform: translateX(-100%); }
  50% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.list-list{
  height: calc( 100% - 56px );
  overflow-y: auto;
}

.empty-contents{
  text-align: center;
  .text{
    font-size: 1.6rem;
    margin: 1rem auto;
  }
}

.MuiAvatar-colorDark{
  background: $color_text !important;
  color: $color_background !important;
}

.dragButton{
  color: $color_text !important;
}