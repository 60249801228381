@import '../_vars';
.listItems{
  color: $color_text;
  .MuiPaper-root{
    background: rgba(255,255,255,0.1);
    color: $color_text;
  }
  .expansion-title{
    flex-grow: 1;
  }
  .expansion-icon{
    margin-right: 1rem;
  }
  .expansion-noitem{
    .MuiButtonBase-root{
      opacity: 0.6;
    }
  }
  .separator{
    background: rgba(255,255,255,0.1);
    height: 1px;
    margin-bottom: 5px;
  }
  &.small{
    .MuiExpansionPanelDetails-root{
      padding: 0 !important;
    }
  }
  .subitems{
    padding-left: 2rem;
  }
}