@import './_vars';
.App {
  text-align: center;
  background-color: $color_background;
  font-size: calc(10px + 2vmin);
  color: $color_text;
}
.main{
  // height: calc( 100% - 70px );
}

.iconButtonLegend{
  position: absolute;
  font-size: 0.55rem;
  text-transform: uppercase;
  bottom: 0;
  // font-weight: bold;
}

// contenu qui prend quasiment tout l'ecran et place la Version en bas
.min-height-screen{
  min-height: calc( 100vh - 145px );
  padding: 1rem 0;
}

// Home ---------------------





// List --------------------

.MuiToolbar-root {
  .title{
    flex-grow: 1;
    text-align: left;
    line-height: 1;
    small{
      line-height: 1;
    }
  }
}

.mode-tabs{
  text-align: center;
  margin: 1rem auto;
  .MuiButtonGroup-root{
    vertical-align: middle;
    margin: 0 1rem;
  }
}
.mode-contents{
  // height: calc( 100% - 30px );
  // overflow-y: auto;
}
.mode-withouttabs{
  // height: 100%;
  // overflow-y: auto;
}

.list-actions{
  margin-top: 2rem;
  text-align: right;
  .MuiButton-root{
    margin-bottom: 5px;
  }
}

.field{
  margin: 2rem 0;
}
.formcontrol-multiselect{
  width: 100%;
}
.multiselect{
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  padding: 1rem;
}
#choosetheme-label,
#chooseshelves-label,
#choosetags-label{
  background: #fff;
  padding: 0 3px;
}

.selectitem-drawer{
  .MuiDrawer-paper{
    background: $color_background;
  }
}

.MuiDialogActions-root.dialog-actions{
  justify-content: space-between;
}


.selectitem-drawer .MuiDrawer-paper {
  max-width: 80%;
}


.scannerWrapper{
  #scanner{
    max-width: 100%;
    max-height: calc( 100% - 100px );
    width: 100%;
    height: 0;
    display: none;
  }
  &.running{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
    height: 80vh;
    z-index: 9999;
    #scanner{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.alert{
  background: rgb(253, 236, 234);
  border-radius: 4px;
  padding: 2px 5px;
  .MuiButton-root{
    float: right;
  }
}

.MuiCardMedia-root{
  height: 0;
  padding-top: 56.25%;
  background-size: auto !important;  // pour conserver toute l'image
}

.MuiSvgIcon-root{
  vertical-align: middle;
}
.MuiTable-root{
  border-radius: 4px;
  overflow: hidden;
}
.MuiTableCell-root{
  color: $color_text !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  &.subtitle{
    text-transform: uppercase;
    background: rgba(255, 255, 255, 0.1) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .priceUnity{
    display: block;
    opacity: 0.5;
    font-size: 0.9em;
    line-height: 0.9;
  }
}
.MuiTableCell-head{
  background: $color_text !important;
  color: $color_background !important;
  text-transform: uppercase;
}





.listItems .MuiPaper-root.no-store{
  background: rgba(255, 255, 255, 0.2);
}





.MuiFab-temporary{
  background-color: $color_temporary !important;
  color: $color_background !important;
}
.MuiToolbar-root {
  .MuiIconButton-colorSecondary{
    color: #00ff9d !important;
  }
}
.MuiFab-model{
  background-color: $color_model !important;
  color: $color_background !important;
}
.MuiFab-temporary{
  background-color: $color_temporary !important;
  color: $color_background !important;
}
.MuiChip-model{
  background-color: $color_model !important;
  color: $color_background !important;
}

// shelves categories dans les select
.MuiListSubheader-sticky{
  background: #fff !important;
}





.store-cartouche{
  // padding: 0.5rem;
  // border: 1px dotted $color_text;
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 1rem;
  // border-radius: 4px;
  // text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listitem-shelf{
  .MuiListItemAvatar-root{
    min-width: 43px;
  }
  .MuiAvatar-root{
    width: 20px;
    height: 20px;
    font-size: 1rem;
    margin-left: 0.6rem;
    .MuiSvgIcon-root{
      font-size: 1rem;
    }
  }
}



// boutons up / down (stores)
.MuiButton-root.up-down{
  max-width: 30px;
  min-width: 30px;
}